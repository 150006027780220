import { useEffect } from "react";
import "./takeaway.css";
import RunnerUp from "./../../components/runnerup/runnerup";
import m2bg2 from "./../../assets/img/tm/m2bg2.png";
import m2bg1 from "./../../assets/img/tm/m2bg1.png";
import m1 from "./../../assets/img/tm/m1.png";
import next from "./../../assets/img/tm/next.png";
import camdenmenu from "./../../assets/pdfs/camdenmenu.pdf";
import croydonmenu from "./../../assets/pdfs/croydonmenu.pdf";

function Menu() {
  useEffect(() => {
    document.title = "Our Menu";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="takeaway">
      <div className="second">
        <h2>
          For Every Cravings Of Your <span>Indian Food</span>{" "}
        </h2>
        <p>
          Let go of all your preconceptions of Indian food. Experience the real
          flavours of Mumbai and relish the true street food and the best
          authentic flavours.{" "}
        </p>
        <img src={m1} alt="" />

        <div className="menu_item">
          <a href="https://deliveroo.co.uk/menu/london/herne-hill/curry-on-naanstop-the-hall?day=today&geohash=gcpuv8q08z3n&time=1545">
            <h4>Herne Hill</h4>
            <img src={next} alt="" />
          </a>
        </div>

        <div className="menu_item">
          <a href="https://deliveroo.co.uk/menu/London/battersea/curry-on-naanstop-editions-bqr?geohash=gcpuu4px5x9j">
            <h4>BatterSea</h4>
            <img src={next} alt="" />
          </a>
        </div>

        <div className="menu_item">
          <a href="https://deliveroo.co.uk/menu/London/editions-canning-town/curry-on-naanstop-editions-can?geohash=u10j069xes6e">
            <h4>Canning Town</h4>
            <img src={next} alt="" />
          </a>
        </div>

        <div className="menu_item">
          <a href={camdenmenu} target="_blank" rel="noreferrer">
            <h4>Camden Lock</h4>
            <img src={next} alt="" />
          </a>
        </div>

        <div className="menu_item">
          <a href={croydonmenu} target="_blank" rel="noreferrer">
            <h4>Box Park Croydon</h4>
            <img src={next} alt="" />
          </a>
        </div>

        <img src={m2bg1} alt="" id="m2bg1" />
        <img src={m2bg2} alt="" id="m2bg2" />
      </div>

      <RunnerUp />
    </div>
  );
}

export default Menu;
