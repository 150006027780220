import { useEffect } from "react";
import "./eachlocation.css";
import "./cucumber.css";
import RunnerUp from "./../../components/runnerup/runnerup";
import camdenmenu from "./../../assets/pdfs/camdenmenu.pdf";
import camdenallergenmatrix from "./../../assets/pdfs/camdenallergenmatrix.pdf";

function Camden() {
  useEffect(() => {
    document.title = "Camden Lock";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="el">
      <div className="first" id="camden">
        <h1 style={{ color: "white" }}>Camden Lock</h1>
      </div>

      <div className="second">
        <h2>Find us at Camden Lock</h2>

        <div className="links" style={{ gap: "25px" }}>
          <a href={camdenmenu} target="_blank" rel="noreferrer">
            Menus
          </a>
          <a href={camdenallergenmatrix}>Allergen Matrix</a>
        </div>
      </div>

      <div className="third">
        <div className="third_1">
          <iframe
            title="Google Maps - Curry On Naanstop Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.4756528764583!2d-0.14904462387033174!3d51.541176508235864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ae15628cc1b%3A0xbff88a374f27a160!2s54-56%20Camden%20Lock%20Pl%2C%20London%20NW1%208AF%2C%20UK!5e0!3m2!1sen!2sin!4v1731487101382!5m2!1sen!2sin"
            width={600}
            height={550}
            style={{ border: "0px" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <div className="third_2">
          <div className="contact_us">
            <h4>Address</h4>
            <span>
              54-56 Camden Lock PL, <br /> London NW1 8AF, <br /> United Kingdom
            </span>
          </div>
          <div className="contact_us">
            <h4>Opening Hours</h4>
            <span>
              Monday to Friday - 12-10PM <br /> Saturday-10am to 10 pm <br />{" "}
              Sunday 10am to 9pm
            </span>
          </div>
          <div className="contact_us">
            <h4>Contact Us</h4>
            <span>info@curryonnaanstop.com</span>
            <br />
            <span>020 3929 7560</span>
          </div>
        </div>
      </div>

      <RunnerUp />
    </div>
  );
}

export default Camden;
