import { useEffect } from "react";
import "./eachlocation.css";
import RunnerUp from "./../../components/runnerup/runnerup";
import deliveroologo from "./../../assets/img/deliveroologo.png";

function Hernehill() {
  /* 
  const [popupdisplay, setpopupdisplay] = useState('none')
  const openpopup = () => {
    setpopupdisplay('flex')
  }
 */
  useEffect(() => {
    document.title = "Herne Hills";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="el">
      <div className="first" id="hernehill">
        <h1 style={{ color: "white" }}>Herne Hill</h1>
      </div>

      <div className="second">
        <h2>Find us at Herne Hill</h2>

        <div class="links">
          <a href="https://deliveroo.co.uk/menu/london/herne-hill/curry-on-naanstop-the-hall?day=today&geohash=gcpuv8q08z3n&time=1545">
            Order with <img src={deliveroologo} alt="" />
          </a>
          {/* <a onClick={openpopup}>Dine In</a> */}
        </div>
      </div>

      <div className="third">
        <div className="third_1">
          <iframe
            title="Google Maps - Curry On Naanstop Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2485.8298112051493!2d-0.10324333280157685!3d51.46128106952368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8b7d60c6aeff3%3A0x2e16aaafc0e57574!2sCurry%20On%20Naanstop!5e0!3m2!1sen!2sin!4v1731485187237!5m2!1sen!2sin"
            width={600}
            height={550}
            style={{ border: "0px" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <div className="third_2">
          <div className="contact_us">
            <h4>Address</h4>
            <span>
              Unit 12 , Stone Trading Estate <br />
              Milkwood Road SE24 OJU
            </span>
          </div>
          <div className="contact_us">
            <h4>Opening Hours</h4>
            <span>
              Monday to Friday - 12-10PM <br /> Saturday-10am to 10 pm <br />{" "}
              Sunday 10am to 9pm
            </span>
          </div>
          <div className="contact_us">
            <h4>Contact Us</h4>
            <span>info@curryonnaanstop.com</span>
            <br />
            <span>020 3929 7560</span>
          </div>
        </div>
      </div>

      <RunnerUp />
      {/* <Popup display = {popupdisplay} setpopupdisplay={setpopupdisplay} /> */}
    </div>
  );
}

export default Hernehill;
